import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty, find, map, filter, includes, toLower, head } from "lodash";
import moment from "moment";
import { PageWrapper } from "../../../components";
import { ConfirmDialog, EmptyInfo, LoadingMask } from "../../../common";
import { notificationAction } from "../../../actions";
import "./searcherOpportunityDraft.scss";
import {
  getShortlistsApi,
  getShortlistResumesApi,
  getDraftOpportunityDetailApi,
  deleteOpportunityApi,
  deleteOpportunityAndAssociationsApi,
  getSavedSearchesApi,
  saveOpportunity,
  getAllLocationsApi,
  getAllLanguagesApi,
  getDraftOpportunitesApi,
} from "../../Opportunity/opportunityApi";
import { getCompaniesApi } from "../../Company/companyApi";
import {
  deleteResumeApi,
  getShortlistResumeApi,
} from "../../Shortlist/shortlistApi";
import { isNullOrUndefined } from "util";
import { onStateChangeAction } from "./searcherOpportunityDraftAction";
import { getCurrenciesApi } from "../../Collaboration/common/collaborationApi";
import { Col, Row, Select } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import NewSearcherOpportunityForm from "./NewCreateOpportunity/component/NewSearcherOpportunityForm";
import ShortListResume from "./NewCreateOpportunity/component/resumeResumeList";
import MobileFilter from "./NewCreateOpportunity/component/mobileFilter.js";
import DetailModalCreateOpportunity from "./NewCreateOpportunity/component/detailModal";
import UpdateTitleModal from "../../../components/updateTitleModal/updateTitleModal";

const { Option } = Select;

class NewSearcherOpportunityDraft extends Component {
  constructor(props) {
    super(props);

    this.selectedOpportunity = {
      invalidSelectedCompany: false,
      invalidRequestName: false,
      invalidFeeCurrencyType: false,
      invalidStartDate: false,
      invalidDurationType: false,
      invalidSelectedSavedSearch: false,
      Countries: [],
      Description: "",
      Duration: 4,
      DurationType: "",
      FeeCurrencyType: "",
      HourlyFee: "",
      Language: [],
      RequestName: "",
      StartDate: "",
    };
    this.state = {
      isSearchFocus: false,
      searchListHover: false,
      isLoading: false,
      mobileModal: false,
      updateTitleModal: {
        open: false,
      },
    };
  }

  getOpportunities = () => {
    const { isFreelancer } = this.props;
    this.props.onStateChangeAction({ isFetching: true, shortlistResumes: [] });
    this.setState((st) => ({ ...st, isLoading: true }));
    getDraftOpportunitesApi({ isFreelancer })
      .then((response) => {
        const { success, items } = response;
        if (success) {
          this.setState((st) => ({
            ...st,
            updateTitleModal: {
              ...st.updateTitleModal,
              id: items?.Draft?.[0]?.RequestId,
              value: items?.Draft?.[0]?.RequestName,
            },
          }));
          const opportunities =
            !isEmpty(items) && items.Draft ? items.Draft : [];
          const filterData = items.Draft?.map((single) => {
            return {
              ...single,
              label: single?.RequestName,
              value: single?.RequestId,
            };
          });
          this.props.onStateChangeAction({
            opportunities: filterData,
            filterOpportunires: filterData,
            isFetching: false,
          });
          if (!isEmpty(filterData)) {
            this.handleOpportunityClick({
              selectedOpportunity: filterData?.[0],
            });
          }
          this.setState((st) => ({ ...st, isLoading: false }));
          const { onBoardOpportunityId } = this.props;
          if (onBoardOpportunityId) {
            this.props.onStateChangeAction({
              opportunities,
              filteredOpportunity: opportunities.filter(
                (a) => a.RequestId == onBoardOpportunityId
              ),
            });
            this.handleOpportunityClick({
              selectedOpportunity: opportunities.find(
                (a) => a.RequestId == onBoardOpportunityId
              ),
            });
          }
        }
      })
      .catch(() => {
        this.setState((st) => ({ ...st, isLoading: false }));
      });
  };

  getShotlists = () => {
    getShortlistsApi()
      .then((response) => {
        if (response.success) {
          const shortlist = response?.items?.map((item) => ({
            ...item,
            value: item?.ShortlistId,
            label: item?.ShortlistName,
          }));
          this.props.onStateChangeAction({
            shortlists: shortlist,
            filterShortlists: shortlist,
          });
        }
      })
      .catch((response) => response);
  };

  getLocations = () => {
    getAllLocationsApi().then((response) => {
      if (response.success) {
        const locations = response.items.map((item) => ({
          ...item,
          value: item.CountryId,
          label: item.CountryName,
        }));
        this.props.onStateChangeAction({ locations });
      }
    });
  };
  getLanguages = () => {
    getAllLanguagesApi().then((response) => {
      if (response.success) {
        const languages = response.items.map((item) => ({
          ...item,
          value: item.LanguageId,
          label: item.LanguageValue,
        }));
        this.props.onStateChangeAction({ languages });
      }
    });
  };
  getCurrencies() {
    getCurrenciesApi()
      .then((res) => {
        if (res.success) {
          const allCurrenciesList = res.items.map((item) => ({
            ...item,
            value: item.CurrencyId,
            label: item.Name,
          }));
          this.props.onStateChangeAction({ allCurrenciesList });
        }
      })
      .catch((err) => console.log("Err ", err));
  }
  componentDidMount() {
    this.getOpportunities();
    this.getShotlists();
    this.getLanguages();
    this.getLocations();
    this.getCurrencies();
    getCompaniesApi().then((response) => {
      if (response.success) {
        const { items } = response;
        this.props.onStateChangeAction({
          userCompanies: map(items, ({ CompanyName, UserCompanyId }) => {
            return { label: CompanyName, value: UserCompanyId };
          }),
        });
      }
    });
    getSavedSearchesApi().then((response) => {
      if (response.success) {
        const { items } = response;
        this.props.onStateChangeAction({
          savedSearches: map(items, ({ SearchName, SavedSearchId }) => {
            return { label: SearchName, value: SavedSearchId };
          }),
        });
      }
    });
    const { onBoardOpportunityId } = this.props;
    if (onBoardOpportunityId) {
      this.getOpportunities();
    }
    if (this?.props?.history?.location?.pathname?.split("/")?.length > 2) {
      this.setState((st) => ({
        ...st,
        updateTitleModal: { open: true, title: "Opportunity" },
      }));
    }
  }

  handleOpportunityClick = ({ selectedOpportunity }) => {
    this.setState((st) => ({ ...st, isLoading: true }));
    const { RequestId } = selectedOpportunity;
    let selectedShortlist = null;
    this.props.onStateChangeAction({
      isFetchingShortlist: true,
      currentOpportunityId: RequestId,
      isFetchingShortlistResume: true,
      selectedShortlist: [],
      selectedOpportunity: {
        ...this.selectedOpportunity,
        ...selectedOpportunity,
      },
      shortlistResumes: [],
      selectedResume: {},
    });
    getDraftOpportunityDetailApi({ RequestId })
      .then((response) => {
        if (response.success) {
          const { items } = response;
          const {
            ShortLists,
            UserCompanyId,
            SavedSearchedId,
            DurationType,
            Languages,
            Countries,
            StartDate,
            ...rest
          } = items;
          const UserCompany = filter(
            this.props.userCompanies,
            (company) => company.value === UserCompanyId
          )[0];
          const SavedSearches = filter(
            this.props.savedSearches,
            (search) => search.value === SavedSearchedId
          )[0];
          if (isEmpty(ShortLists)) {
            selectedOpportunity = {
              ...this.props.selectedOpportunity,
              Language: Languages,
              selectedLanguages: Languages?.map((single) => single?.LanguageId),
              selectedLocation: Countries?.map((single) => single?.CountryId),
              DurationType: DurationType || "Weeks",
              Countries,
              StartDate:moment(StartDate),
              FeeCurrencyType: this.props.allCurrenciesList.find(
                (x) => x.CurrencyId == items.FeeCurrencyType
              ),
              ...rest,
            };
            this.setState((st) => ({ ...st, isLoading: false }));
            this.props.onStateChangeAction({
              selectedOpportunity,
              selectedShortlists: [],
              selectedShortlist: null,
              shortlistResumes: [],
              selectedResume: {},
              selectedCompany: UserCompany ? UserCompany : {},
              selectedSaveSearch: SavedSearches ? SavedSearches : {},
              isFetchingShortlist: false,
            });
            return;
          }
          selectedShortlist = head(ShortLists);

          this.props.onStateChangeAction({
            selectedOpportunity: {
              ...this.props.selectedOpportunity,
              Language: Languages,
              selectedLanguages: Languages?.map((single) => single?.LanguageId),
              selectedLocation: Countries?.map((single) => single?.CountryId),
              selectedShortlists: ShortLists?.map(
                (single) => single?.ShortlistId
              ),
              Countries,
              StartDate:moment(StartDate),
              DurationType: DurationType || "Weeks",
              FeeCurrencyType: this.props.allCurrenciesList.find(
                (x) => x.CurrencyId == items.FeeCurrencyType
              ),
              ...rest,
            },

            selectedCompany: UserCompany ? UserCompany : {},
            selectedSaveSearch: SavedSearches ? SavedSearches : {},
            selectedShortlist: ShortLists,
            isFetchingShortlist: false,
          });
          getShortlistResumesApi({ ShortlistId: selectedShortlist.ShortlistId })
            .then((response) => {
              if (response.success) {
                const { items } = response;
                if (isEmpty(items)) {
                  const info = {
                    message: this.props.labels
                      .InfoSearcherOpportunityDraftEmptyShortlist,
                    status: "info",
                  };
                  this.props.notificationAction(info);
                  this.setState((st) => ({ ...st, isLoading: false }));
                  return;
                }
                this.props.onStateChangeAction({
                  shortlistResumes: items,
                  selectedResume: head(items),
                  isFetchingShortlistResume: false,
                });
              }
              this.setState((st) => ({ ...st, isLoading: false }));
            })
            .catch((response) => {
              this.setState((st) => ({ ...st, isLoading: false }));
            });
        }
      })
      .catch((response) => {
        this.setState((st) => ({ ...st, isLoading: false }));
        this.props.onStateChangeAction({
          isFetchingShortlist: false,
          isFetchingShortlistResume: false,
        });
      });
  };

  handleDateChange = (date) => {
    const { selectedOpportunity } = this.props;
    try {
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          invalidStartDate: false,
          StartDate: date,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    const { opportunities } = this.props;
    const filterOpportunires = filter(opportunities, (opportunity) =>
      includes(toLower(opportunity.RequestName), toLower(value))
    );
    this.props.onStateChangeAction({ filterOpportunires, searchKey: value });
  };

  handleOpportunityDelete = ({ selectedOpportunity, e }) => {
    e.stopPropagation();
    this.props.onStateChangeAction({
      opportunityToDelete: selectedOpportunity,
      dialogMessage: this.props.labels
        .InfoSearcherOpportunityDraftOpportunityDeleteConformationMsg,
    });
  };

  handleYesClick = () => {
    const { yesClickCount, opportunityToDelete } = this.props;
    const { RequestId } = opportunityToDelete;
    if (yesClickCount === 0) {
      this.props.onStateChangeAction({
        dialogMessage: this.props.labels
          .InfoSearcherOpportunityDraftOpportunityDeleteAssociationsConformationMsg,
        yesClickCount: yesClickCount + 1,
      });
      return;
    }
    this.props.onStateChangeAction({
      dialogMessage: "",
      yesClickCount: 0,
    });

    this.props.onStateChangeAction({ isLoading: true });
    this.setState((st) => ({ ...st, isLoading: true }));
    deleteOpportunityAndAssociationsApi({
      RequestId,
    })
      .then((response) => {
        if (!response.success) {
          this.props.onStateChangeAction({
            isLoading: false,
            selectedOpportunity: null,
            opportunity: null,
          });
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        } else {
          this.props.onStateChangeAction({
            isLoading: false,
          });
          const info = {
            message: response.message,
            status: "success",
          };
          this.props.notificationAction(info);
        }
        setTimeout(() => {
          this.deleteOpportunity({ RequestId });
        }, 5000);
      })
      .catch((response) => {
        this.setState((st) => ({ ...st, isLoading: false }));
        this.props.onStateChangeAction({
          isLoading: false,
          selectedOpportunity: null,
          opportunity: null,
        });
      });
  };

  deleteOpportunity = ({ RequestId }) => {
    this.setState((st) => ({ ...st, isLoading: true }));
    deleteOpportunityApi({ RequestId })
      .then((response) => {
        if (response.success) {
          const info = {
            message: this.props.labels.oppDeletedSuccessfully,
            status: "success",
          };
          this.props.notificationAction(info);
          this.getOpportunities();
          this.props.onStateChangeAction({
            isLoading: false,
            selectedOpportunity: null,
            opportunity: null,
          });
        }
        this.setState((st) => ({ ...st, isLoading: false }));
        this.props.onStateChangeAction({
          isLoading: false,
          selectedOpportunity: null,
          opportunity: null,
        });
      })
      .catch((response) => {
        this.setState((st) => ({ ...st, isLoading: false }));
        this.props.onStateChangeAction({
          isLoading: false,
          selectedOpportunity: null,
          opportunity: null,
        });
      });
  };
  handleNoClick = () => {
    const { labels } = this.props;
    const { dialogMessage } = this.props;
    if (
      dialogMessage ===
      labels.InfoSearcherOpportunityDraftOpportunityDeleteAssociationsConformationMsg
    ) {
      const {
        opportunityToDelete: { RequestId },
      } = this.props;
      this.props.onStateChangeAction({ isLoading: true });
      this.deleteOpportunity({ RequestId });
    }
    this.props.onStateChangeAction({ dialogMessage: "", yesClickCount: 0 });
  };

  handleSearchBlur = () => {
    const { searchListHover } = this.state;
    if (searchListHover) return;
    this.setState({ isSearchFocus: false });
  };

  handleShortListSelect = ({ selectedShortlist }) => {
    const { selectedShortlists } = this.props;
    const alreadySelected = filter(
      selectedShortlists,
      (shortlist) => shortlist.ShortlistId === selectedShortlist.ShortlistId
    );
    if (isEmpty(alreadySelected)) {
      this.props.onStateChangeAction({
        selectedShortlists: [...selectedShortlists, selectedShortlist],
      });
      this.setState({
        isSearchFocus: false,
      });
      return;
    }
    this.setState({ isSearchFocus: false });
  };

  handleFormSelectChange = (name, selectedOption) => {
    if (name === "selectedSavedSearch") {
      this.props.onStateChangeAction({
        selectedSaveSearch: isNullOrUndefined(selectedOption)
          ? null
          : selectedOption,
        selectedOpportunity: {
          ...this.props.selectedOpportunity,
          invalidSelectedSavedSearch: false,
        },
      });
      return;
    }
    if (!selectedOption) return;
    if (name === "SelectedCompany") {
      this.props.onStateChangeAction({
        selectedCompany: selectedOption,
        selectedOpportunity: {
          ...this.props.selectedOpportunity,
          invalidSelectedCompany: false,
        },
      });
      return;
    }
    if (name === "FeeCurrencyType") {
      this.setState((st) => ({
        ...st,
        validation: {
          ...st.validation,
          feeCurrencyType: false,
        },
      }));
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...this.props.selectedOpportunity,
          invalidFeeCurrencyType: false,
          FeeCurrencyType: selectedOption,
        },
      });
      return;
    }
    if (name === "DurationType") {
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...this.props.selectedOpportunity,
          invalidDurationType: false,
          DurationType: selectedOption,
        },
      });
      return;
    }
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...this.props.selectedOpportunity,
        [name]: selectedOption,
      },
    });
  };

  handleLanguageSelect = (e, name, option) => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        selectedLanguages: e,
        Language: option,
      },
    });
  };

  handleLocationSelect = (e, name, option) => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        selectedLocation: e,
        Countries: option,
      },
    });
  };

  handleShortlistSearchChange = (e) => {
    const { value } = e.target;
    const { shortlists } = this.props;
    const filterShortlists = filter(shortlists, (shortlist) =>
      includes(toLower(shortlist.ShortlistName), toLower(value))
    );
    this.props.onStateChangeAction({ filterShortlists });
  };

  handleSelectedShortlistClick = (option) => {
    this.setState((st) => ({ ...st, isLoading: true }));
    const { selectedOpportunity } = this.props;
    getShortlistResumesApi({ ShortlistId: option?.ShortlistId })
      .then((response) => {
        if (response.success) {
          const { items } = response;
          const selectedResume = head(items);
          if (isEmpty(items)) {
            const info = {
              message: this.props.labels
                .InfoSearcherOpportunityDraftEmptyShortlist,
              status: "error",
            };
            this.props.notificationAction(info);
          }
          this.props.onStateChangeAction({
            selectedShortlist: [option],
            selectedOpportunity: {
              ...selectedOpportunity,
              selectedShortlists: option?.ShortlistId,
              [`invalidShortList`]: false,
            },
            shortlistResumes: items,
            selectedResume,
            isFetchingShortlistResume: false,
          });
        }
        this.setState((st) => ({ ...st, isLoading: false }));
      })
      .catch((response) => {
        this.setState((st) => ({ ...st, isLoading: false }));
      });
  };

  handleSelectedShortlistDelete = ({ shortlist, e }) => {
    e.stopPropagation();
    let { selectedShortlists } = this.props;
    selectedShortlists = filter(
      selectedShortlists,
      (item) => item.ShortlistId !== shortlist.ShortlistId
    );
    this.props.onStateChangeAction({
      selectedShortlists,
      shortlistResumes: [],
      selectedResume: {},
    });
  };

  validateField = ({ name, value }) => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        [`invalid${name}`]: !value,
        [name]: value,
      },
    });
  };

  setFieldValue = ({ name, value }) => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        [name]: value,
      },
    });
  };

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "RequestName") {
      this.validateField({ name, value });
      return;
    }
    if (name === "HourlyFee") {
      if (isNaN(value)) {
        return;
      }
      this.setState((st) => ({
        ...st,
        validation: {
          ...st.validation,
          hourlyRate: false,
        },
      }));
    }
    this.setFieldValue({ name, value });
  };

  handleResumeSelect = (selectedResume) => {
    const { shortlistResumes } = this.props;
    selectedResume = find(shortlistResumes, {
      ResumeId: selectedResume.ResumeId,
    });
    this.props.onStateChangeAction({ selectedResume });
  };

  handleResumeDelete = (item) => {
    let { shortlistResumes, selectedResume, selectedShortlist } = this.props;
    deleteResumeApi(item.ResumeId, selectedShortlist.ShortlistId)
      .then((data) => {
        if (data.success) {
          shortlistResumes = shortlistResumes.filter(
            (x) => x.ResumeId !== item.ResumeId
          );
          this.props.onStateChangeAction({
            shortlistResumes,
            selectedResume:
              item.ResumeId == selectedResume.ResumeId ? {} : selectedResume,
          });
        }
      })
      .catch((err) => console.log("Error ", err));
  };

  handleSliderChange = (value) => {
    const { selectedOpportunity } = this.props;
    if (value) {
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          Duration: value,
        },
      });
    }
  };

  handleCountryDelete = ({ selectedCountry }) => {
    const { selectedOpportunity } = this.props;
    const filterCountries = filter(
      selectedOpportunity.Countries,
      (country) => country.CountryId !== selectedCountry.CountryId
    );
    this.props.onStateChangeAction({
      ...this.props,
      selectedOpportunity: {
        ...this.props.selectedOpportunity,
        Countries: filterCountries,
      },
    });
  };

  handleLanguageDelete = ({ selectedLanguage }) => {
    const { selectedOpportunity } = this.props;
    const filterLanguages = filter(
      selectedOpportunity.Language,
      (country) => country.LanguageId !== selectedLanguage.LanguageId
    );
    this.props.onStateChangeAction({
      ...this.props,
      selectedOpportunity: {
        ...this.props.selectedOpportunity,
        Language: filterLanguages,
      },
    });
  };

  handleOkClick = () => {
    this.handleOpportunityUpdate({ IsSent: true });
    this.props.onStateChangeAction({ dialogMessage: "" });
  };

  handleSelectShortlist = (item) => {
    this.props.onStateChangeAction({
      selectedShortlist: item,
      shortlistResumes: [],
      selectedResume: {},
    });
    getShortlistResumeApi(item.ShortlistId)
      .then((data) => {
        this.props.onStateChangeAction({
          shortlistResumes: data.items,
          fetchingResumes: false,
          selectedResume: head(data.items) || {},
        });
      })
      .catch((err) => {
        this.props.onStateChangeAction({ fetchingResumes: false });
      });
  };

  handleOpportunitySend = () => {
    const {
      selectedShortlists,
      selectedShortlist,
      selectedOpportunity,
      selectedCompany,
      selectedSaveSearch,
    } = this.props;

    const {
      RequestName,
      StartDate,
      DurationType,
      HourlyFee,
      FeeCurrencyType,
    } = selectedOpportunity;
    if (!RequestName) {
      const info = {
        message: this.props.labels.InfoSearcherOpportunityDraftTitleRequiredMsg,
        status: "error",
      };
      this.validateField({ name: "RequestName", value: RequestName });
      this.props.notificationAction(info);
      return;
    }
    if (!StartDate) {
      const info = {
        message: this.props.labels
          .InfoSearcherOpportunityDraftStartDateRequiredMsg,
        status: "error",
      };
      this.props.notificationAction(info);
      this.validateField({ name: "StartDate", value: StartDate });
      return;
    }
    if (!DurationType) {
      const info = {
        message: this.props.labels
          .InfoSearcherOpportunityDraftDurationTypeRequiredMsg,
        status: "error",
      };
      this.props.notificationAction(info);
      this.validateField({ name: "DurationType", value: DurationType });
      return;
    }
    if (!HourlyFee) {
      const info = {
        message: this.props.labels
          .searcher_phillip_automate_oppertunity_hourlyrate_alert,
        status: "error",
      };
      this.props.notificationAction(info);
      this.setState((st) => ({
        ...st,
        validation: {
          ...st.validation,
          hourlyRate: true,
        },
      }));
      return;
    }
    if (!FeeCurrencyType) {
      const info = {
        message: this.props.labels.collCurrancyTypeRequired,
        status: "error",
      };
      this.props.notificationAction(info);
      const { selectedOpportunity } = this.props;
      this.setState((st) => ({
        ...st,
        validation: {
          ...st.validation,
          feeCurrencyType: true,
        },
      }));
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          [`invalidFeeCurrencyType`]: !FeeCurrencyType,
          FeeCurrencyType: FeeCurrencyType,
        },
      });
      return;
    }
    if (isEmpty(selectedCompany)) {
      const info = {
        message: this.props.labels
          .InfoSearcherOpportunityDraftUserCompanyRequiredMsg,
        status: "error",
      };
      this.props.notificationAction(info);
      const { selectedOpportunity } = this.props;
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          [`invalidSelectedCompany`]: true,
          selectedCompany: selectedCompany,
        },
      });
      return;
    }

    if (isEmpty(selectedShortlist)) {
      const info = {
        message: this.props.labels
          .InfoSearcherOpportunityDraftSendEmptyShortlistMsg,
        status: "error",
      };
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          [`invalidShortList`]: true,
        },
      });
      this.props.notificationAction(info);
      return;
    }
    this.props.onStateChangeAction({
      dialogMessage: this.props.labels
        .InfoSearcherOpportunityDraftSendConformationMsg,
    });
  };

  handleOpportunityUpdate = ({ IsSent = false }) => {
    const {
      selectedOpportunity,
      selectedShortlist,
      selectedCompany,
      selectedSaveSearch,
    } = this.props;
    const { invalidRequestName, RequestName } = selectedOpportunity;

    if (!RequestName) {
      this.validateField({ name: "RequestName", value: RequestName });
      const info = {
        message: this.props.labels.InfoSearcherOpportunityDraftTitleRequiredMsg,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }

    if (IsSent) {
      if (selectedCompany && !selectedCompany.value) {
        const info = {
          message: this.props.labels
            .InfoSearcherOpportunityDraftCompanyRequiredMsg,
          status: "error",
        };
        this.props.notificationAction(info);
        this.props.onStateChangeAction({
          selectedOpportunity: {
            ...selectedOpportunity,
            invalidSelectedCompany: true,
          },
        });
        return;
      }
    }

    const LanguageIds = map(
      selectedOpportunity.Language,
      (language) => language.LanguageId
    );
    const LocationsIds = map(
      selectedOpportunity.Countries,
      (country) => country.CountryId
    );

    const ShortListIds = map(
      selectedShortlist,
      (shortlist) => shortlist.ShortlistId
    );

    const opportunity = {
      Countries: [],
      CreateDate: selectedOpportunity.CreateDate,
      Description: selectedOpportunity.Description,
      Duration: selectedOpportunity.Duration,
      FeeCurrencyType: selectedOpportunity.FeeCurrencyType
        ? typeof selectedOpportunity.FeeCurrencyType === "string"
          ? selectedOpportunity.FeeCurrencyType
          : selectedOpportunity.FeeCurrencyType.value
        : null,
      DurationType: selectedOpportunity.DurationType
        ? typeof selectedOpportunity.DurationType === "string"
          ? selectedOpportunity.DurationType
          : selectedOpportunity.DurationType.value
        : null,
      EndDate: selectedOpportunity.EndDate,
      HourlyFee: selectedOpportunity.HourlyFee,
      IsSent,
      Language: [],
      LanguageIds,
      LocationsIds,
      RequestCountries: null,
      RequestId: selectedOpportunity.RequestId,
      RequestName: selectedOpportunity.RequestName,
      SavedSearchedId: selectedSaveSearch ? selectedSaveSearch.value : null,
      ShortListIds,
      StartDate: selectedOpportunity.StartDate,
      UserCompanyId: selectedCompany ? selectedCompany.value : null,
    };
    this.props.onStateChangeAction({ opportunitySaveLoading: true });
    saveOpportunity({ opportunity }).then((response) => {
      if (response.success) {
        if (IsSent) {
          this.props.onStateChangeAction({ selectedOpportunity: null });
          const info = {
            message: this.props.labels
              .InfoSearcherOpportunityDraftSendSuccessfullyMsg,
            status: "success",
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            message: this.props.labels
              .InfoSearcherOpportunityDraftSavedSuccessfullyMsg,
            status: "success",
          };
          this.props.notificationAction(info);
        }
        this.getOpportunities();
        this.props.onStateChangeAction({ opportunitySaveLoading: false });
        return;
      }

      const info = {
        message: response.message,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({ opportunitySaveLoading: false });
    });
  };
  handleModalClick = (data) => {
    this.setState((st) => ({
      ...st,
      data: data,
      detailModal: true,
    }));
  };

  handleCloseModal = () => {
    this.setState((st) => ({
      ...st,
      data: "",
      detailModal: false,
    }));
  };

  handleListOpenMobile = () => {
    this.setState((st) => ({ ...st, mobileModal: true }));
  };
  handleUpdateTitle = () => {
    const opportunity = {
      RequestId: this.state.updateTitleModal.id,
      RequestName: this.state.updateTitleModal.value,
    };
    saveOpportunity({ opportunity })
      .then((response) => {
        if (response.success) {
          const info = {
            message: this.props.labels
              .InfoSearcherOpportunityDraftSavedUpdatedSuccessfullyMsg,
            status: "success",
          };
          this.props.notificationAction(info);
          this.getOpportunities();
          this.setState((st) => ({ ...st, updateTitleModal: { open: false } }));
          return;
        }
        const info = {
          message: response.message,
          status: "error",
        };
        this.props.notificationAction(info);
        this.setState((st) => ({ ...st, updateTitleModal: { open: false } }));
      })
      .catch((err) => {
        console.log("Err", err);
      });
  };

  updateTitle = (e) => {
    if (typeof e == "object") {
      this.setState((st) => ({ ...st, updateTitleModal: { open: false } }));
    } else {
      this.setState((st) => ({
        ...st,
        updateTitleModal: { ...st.updateTitleModal, value: e()?.value },
      }));
    }
  };
  render() {
    const { labels, isHelpActive } = this.props;
    const {
      dialogMessage,
      filterOpportunires,
      selectedOpportunity,
      filterShortlists,
      shortlistResumes,
      selectedSaveSearch,
      selectedCompany,
      savedSearches,
      userCompanies,
      languages,
      locations,
      opportunitySaveLoading,
      allCurrenciesList,
    } = this.props;

    const handleMobileModalClose = () => {
      this.setState((st) => ({ ...st, mobileModal: false }));
    };

    return (
      <PageWrapper className="searcher-draft-opportunity-page">
        {this?.state?.isLoading && <LoadingMask />}
        {dialogMessage && (
          <ConfirmDialog testId="confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              {dialogMessage ===
              labels.InfoSearcherOpportunityDraftSendConformationMsg ? (
                <ConfirmDialog.Button
                  testId="searcher-opportunity-draft-ok-btn"
                  onClick={this.handleOkClick}
                >
                  Yes
                </ConfirmDialog.Button>
              ) : (
                <ConfirmDialog.Button
                  testId="searcher-opportunity-draft-yes-btn"
                  onClick={this.handleYesClick}
                >
                  Yes
                </ConfirmDialog.Button>
              )}
              <ConfirmDialog.Button
                testId="searcher-opportunity-draft-no-btn"
                onClick={this.handleNoClick}
              >
                No
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        {this.state?.detailModal == true && (
          <DetailModalCreateOpportunity
            handleCloseModal={this.handleCloseModal}
            open={this.state}
            selected={this.state?.selected}
          />
        )}
        <UpdateTitleModal
          open={this?.state?.updateTitleModal}
          setOpen={this.updateTitle}
          handleUpdateTitle={this.handleUpdateTitle}
        />
        <MobileFilter
          isModalOpen={this?.state?.mobileModal}
          setIsModalOpen={handleMobileModalClose}
          resumeList={shortlistResumes}
          score={0}
          handleModalClick={this.handleModalClick}
          name="opportunity"
        />
        <Row className="new-search-resume-main">
          <Col
            xs={24}
            md={selectedOpportunity?.RequestId ? 6 : 24}
            className="h-100"
          >
            <div className="h-100 flex flex-column">
              <div style={{ overflow: "auto" }} className="flex flex-column">
                <div
                  className="select-sent"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Select
                    style={{
                      width: "100%",
                      marginRight: "6px",
                      overflow: "auto",
                    }}
                    size="large"
                    optionLabelProp="label"
                    placeholder={
                      labels.SearcherOpportunityDraftSearchPlaceholder
                    }
                    onChange={(e) => {
                      const filterOption = filterOpportunires?.find(
                        (row) => row?.value == e
                      );
                      this.handleOpportunityClick({
                        selectedOpportunity: filterOption,
                      });
                    }}
                    value={selectedOpportunity?.value}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.props?.label
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    }
                  >
                    {filterOpportunires?.map((task) => {
                      return (
                        <Option
                          key={task.id}
                          value={task.value}
                          label={task.label}
                        >
                          <div className="align-items-center d-flex w-100 justify-content-between">
                            <span
                              style={{
                                paddingRight: "6px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {task.label}
                            </span>
                            <span>
                              <CloseCircleOutlined
                                style={{
                                  fontSize: "16px",
                                  color: "#a8a6a6",
                                  lineHeight: "22px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.handleOpportunityDelete({
                                    selectedOpportunity: task,
                                    e,
                                  });
                                }}
                              />
                            </span>
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    overflow: "auto",
                    paddingRight: "6px",
                  }}
                >
                  {selectedOpportunity?.RequestName ? (
                    <NewSearcherOpportunityForm
                      state={this.state}
                      shortlist={filterShortlists}
                      handleSelectedShortlistClick={
                        this.handleSelectedShortlistClick
                      }
                      selectedOpportunity={selectedOpportunity}
                      selectedCompany={selectedCompany}
                      selectedSaveSearch={selectedSaveSearch}
                      userCompanies={userCompanies}
                      savedSearches={savedSearches}
                      languages={languages}
                      labels={labels}
                      isLoading={opportunitySaveLoading}
                      allLocations={locations}
                      allLanguages={languages}
                      onFormSelectChange={this.handleFormSelectChange}
                      onFormFieldChange={this.handleFormFieldChange}
                      onDateChange={this.handleDateChange}
                      onSliderChange={this.handleSliderChange}
                      onLanguageSelect={this.handleLanguageSelect}
                      onLocationSelect={this.handleLocationSelect}
                      onOpportunityUpdate={this.handleOpportunityUpdate}
                      onOpportunitySend={this.handleOpportunitySend}
                      allCurrencies={allCurrenciesList}
                      handleListOpenMobile={this.handleListOpenMobile}
                    />
                  ) : (
                    <EmptyInfo>{labels?.emptyOpportunityInfo}</EmptyInfo>
                  )}
                </div>
              </div>
            </div>
          </Col>
          {selectedOpportunity?.RequestId && (
            <Col md={18} xs={0} offset={0.5} className="h-100">
              <div className="column-secend">
                <ShortListResume
                  resumeList={shortlistResumes}
                  score={0}
                  handleModalClick={this.handleModalClick}
                  name="opportunity"
                  label="No Resume found"
                />
              </div>
            </Col>
          )}
        </Row>
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({
  systemLabel,
  userInfo,
  navigation,
  searcherOpportunityDraft,
}) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  let { IsFreelancer, UserId } = user;
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));
  const userId =
    UserId !== undefined ? UserId : parseInt(sessionStorage.getItem("userId"));
  return {
    labels,
    isFreelancer: IsFreelancer,
    userId,
    isHelpActive,
    ...searcherOpportunityDraft,
  };
};

export default connect(mapStateToProps, {
  notificationAction,
  onStateChangeAction,
})(NewSearcherOpportunityDraft);
