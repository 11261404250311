import React, { Component } from "react";
import { EmptyInfo, LoadingMask, Button } from "../../../../../common";
import Slider from "rc-slider";
import moment from "moment";

import "./createFormStyle.scss";
import { DatePicker, Input, Row, Col, Select } from "antd";
import MenuDots from "../../../../../assets/newResume/menuDots";

const { TextArea } = Input;

const Handle = Slider.Handle;

class CreateNewCollaborationForm extends Component {
  viewRender = () => {
    const {
      collaborationForm,
      labels,
      onFormFieldChange,
      onFormSelectChange,
      onDateChange,
      onSliderChange,
      opportunityList,
      iprosList,
      onCollaborationSave,
      Currencies,
      handleListOpenMobile,
    } = this.props;

    const handle = (props) => {
      const { value, dragging, index, ...restProps } = props;
      return (
        <Handle value={value} key={index} {...restProps}>
          {value}
        </Handle>
      );
    };

    const {
      Title,
      invalidTitle,
      StartDate,
      invalidStartDate,
      DurationType,
      invalidDurationType,
      HourlyRate,
      invalidHourlyRate,
      HourlyRateType,
      invalidHourlyRateType,
      RequestId,
      invalidRequestId,
      ResumeId,
      invalidResumeId,
      Description,
      Duration,
    } = collaborationForm;

    const durationTypeList = [
      { value: "Days", label: "Days" },
      { value: "Weeks", label: "Weeks" },
      { value: "Months", label: "Months" },
      { value: "Years", label: "Years" },
    ];

    return (
      <div className="New-create-collobration-searcher">
        <div className="container-collapse">
          <div className="new-collapse-main">
            <p className="collapse-title">{labels.collTitle}</p>
          </div>
          <div>
          <Input
              autoFocus={invalidTitle}
              type="text"
              name="Title"
              style={{ marginTop: "8px" }}
              className={`bg-color-input ${invalidTitle ? "inValid" : ""}`}
              value={Title}
              bordered={false}
              size="large"
              placeholder={labels.collFormTitle}
              onChange={onFormFieldChange}
              onBlur={onFormFieldChange}
              test-data-id="input-text-collaboration-title"
            />
          </div>
        </div>
        <div className="container-collapse">
          <div className="new-collapse-main">
            <p className="collapse-title">{labels.collStartDate}</p>
          </div>
          <div>
          <DatePicker
              name="DatePicker"
              format="DD-MM-YYYY"
              value={StartDate ? moment(StartDate) : null}
              style={{ width: "100%", marginTop: "8px" }}
              onChange={onDateChange}
              size="large"
              placeholder={labels.collStartDate}
              className={`bg-color-input ${invalidStartDate ? "inValid" : ""}`}
              allowClear
              bordered={false}
            />
          </div>
        </div>
        <div className="container-collapse">
          <div className="new-collapse-main">
            <p className="collapse-title">{labels.collDuration}</p>
          </div>
          <Row
            gutter={[12, 8]}
            style={{ marginTop: "8px", alignItems: "center" }}
          >
            <Col xs={24} md={12}>
              <Slider
                name="durationSlider"
                min={0}
                max={20}
                defaultValue={4}
                value={Duration}
                handle={handle}
                onChange={onSliderChange}
              />
            </Col>
            <Col xs={24} md={12}>
              <Select
                autoFocus={invalidDurationType}
                name="DurationType"
                className={`select-input bg-color-for-opportunity  `}
                placeholder={
                  labels?.SearcherOpportunityDraftDetailDurationPlaceholder
                }
                value={DurationType || undefined}
                onChange={(value, selectedOption) =>
                  onFormSelectChange("DurationType", selectedOption)
                }
                options={durationTypeList}
                size="large"
                showArrow
                bordered={false}
                style={{ width: "100%" }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.props?.label
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                }
              />
            </Col>
          </Row>
        </div>
        <div className="container-collapse">
          <div className="new-collapse-main">
            <p className="collapse-title">{labels.collHourlyFee}</p>
          </div>
          <Row gutter={[12, 8]} style={{ marginTop: "8px" }}>
            <Col xs={24} md={12}>
              <Input
                size="large"
                className={`bg-color-for-opportunity  ${
                  invalidHourlyRate ? "inValid" : ""
                }`}
                bordered={false}
                type="text"
                autoComplete="off"
                name="HourlyRate"
                placeholder={labels?.SearcherOpportunityDraftDetailHourlyFee}
                value={HourlyRate}
                onChange={onFormFieldChange}
                onBlur={onFormFieldChange}
                test-data-id="input-txt-opportunity-hourlyRate"
              />
            </Col>
            <Col xs={24} md={12}>
              <Select
                size="large"
                showArrow
                className={`bg-color-for-opportunity  ${
                  invalidHourlyRateType ? "inValid" : ""
                }`}
                bordered={false}
                style={{ width: "100%" }}
                name="HourlyRateType"
                placeholder={
                  labels?.SearcherOpportunityDraftDetailHourlyFeeDropDownPlaceholder
                }
                value={HourlyRateType|| undefined}
                onChange={(e, selectedOption) =>
                  onFormSelectChange("HourlyRateType", selectedOption)
                }
                options={Currencies}
              />
            </Col>
          </Row>
        </div>

        <div className="container-collapse">
          <div className="new-collapse-main">
            <p className="collapse-title">{labels.collOpportunity}</p>
          </div>

          <Select
            size="large"
            showArrow
            className={`bg-color-for-opportunity  ${
              invalidRequestId ? "inValid" : ""
            }`}
            bordered={false}
            style={{ width: "100%" }}
            name="OpprtunityId"
            placeholder={"Select..."}
            value={RequestId || undefined}
            onChange={(value, selectedOption) =>
              onFormSelectChange("OpprtunityId", selectedOption)
            }
            options={opportunityList}
          />
        </div>

        <div className="container-collapse">
          <div className="new-collapse-main">
            <p className="collapse-title">{labels.colliPro}</p>
          </div>
          <Row style={{ alignItems: "center", marginTop: "8px" }}>
            <Col xs={22} md={24}>
              <div>
                <Select
                  size="large"
                  showArrow
                  className={`bg-color-for-opportunity  ${
                    invalidResumeId ? "inValid" : ""
                  }`}
                  bordered={false}
                  style={{ width: "100%" }}
                  name="ResumeId"
                  placeholder={"Select..."}
                  value={ResumeId || undefined}
                  onChange={(value, selectedOption) =>
                    onFormSelectChange("ResumeId", selectedOption)
                  }
                  options={iprosList}
                />
              </div>
            </Col>
            <Col
              md={0}
              xs={2}
              onClick={() => {
                if (ResumeId) {
                  handleListOpenMobile();
                }
              }}
            >
              <MenuDots style={{ float: "right" }} />
            </Col>
          </Row>
        </div>

        <div className="container-collapse">
          <div className="new-collapse-main">
            <TextArea
              rows={6}
              name="Description"
              placeholder={labels?.collDescription}
              value={Description}
              onChange={onFormFieldChange}
              className="bg-color-for-opportunity"
              bordered={false}
            />
          </div>
        </div>

        <div className="btn-footer">
          <button className="btn-send" onClick={onCollaborationSave}>
            {labels.collSendButton}
          </button>
        </div>
      </div>
    );
  };

  render() {
    const { isLoading } = this.props;
    return (
      <>
        {isLoading && <LoadingMask />}
        {this.viewRender()}
      </>
    );
  }
}

export default CreateNewCollaborationForm;
