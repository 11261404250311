import React, { useEffect } from "react";
import { ConfirmDialog, LoadingMask } from "../../common";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { notificationAction } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { DownOutlined, PlusOutlined, UpOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  getShortlistApi,
  postShortlistApi,
  getOppuntinitiesApi,
  searchResumesApi,
  postSearchApi,
  getSavedSearchesApi,
  updateSearchApi,
  getSaveSearchDetailApi,
  deleteSavedSearchApi,
} from "./searchApi";
import "../NewResumeSearch/search.scss";
import './style.scss'
import { privateRoutes } from "../../Routes/routing";
import { withRouter } from "react-router-dom";
import { onStateChangeAction } from "./savedSearchAction";
import {
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Grid,
  Menu,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import DetailModal from "./component/detailModal";
import { useState } from "react";
import {
  getAllLanguagesApi,
  getAllLocationsApi,
  getCertificationsApi,
  getIndustriesApi,
  getKeywordApi,
  getRolesApi,
  getSkillsApi,
} from "../Resume/ResumeEdit/resumeEditApi";
import MobileFilter from "./component/mobileFilter.js";
import ShortListModal from "./createShortListModal";
import SaveSearchModal from "./createSaveSearchModal";
import ResumeListComponent from "../../common/resumeResumeList/resumeList";
import MenuDots from "../../assets/newResume/menuDots";
import { updateSavedSearchName } from "../Headsup/views/SavedSearch/savedSearchApi";
import UpdateTitleModal from "../../components/updateTitleModal/updateTitleModal";
import ResumeListComponentNewDesign from "../../common/resumeListComponentNewDesign/index.js";
import Editicon from '../../assets/images/Icons/edit-icon.png'
import CommonSelect from "../../common/selectFilter/selectFilter.js";
import DeleteSaveSearchModal from "./deleteSaveSearchModal.js";
const { Text, Title } = Typography
const { useBreakpoint } = Grid;

const NewSavedSearch = ({ history }) => {
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const labels = useSelector((state) => state?.systemLabel?.labels);
  const globalState = useSelector((state) => state?.newSavedSearch);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [deleteSerach, setDeleteSearch] = useState({ show: false, title: "" });
  const [updateTitleModal, setUpdateTitleModal] = useState({ open: false });
  const [open, setOpen] = useState({
    opportunity: false,
    shortList: false,
    saveSearch: false,
  });
  const [createModal, setCreateModal] = useState({
    open: false,
    value: "",
    id: "",
  });
  const [createSaveSearchModal, setCreateSaveSearchModal] = useState({
    open: false,
    value: "",
    id: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState({ show: false, data: "" });
  const [loading, setLoading] = useState({
    role: false,
    skill: false,
    industry: false,
    certificate: false,
    keyword: false,
  });
  const [filter, setFilter] = useState({
    SkillIds: [],
    ProfileIds: [],
    Countries: [],
    Languages: [],
    KeywordIds: [],
    IndusteryIds: [],
    CertificationIds: [],
  });
  const [state, setState] = useState({
    collapsedLocation: true,
    resumeList: [],
    hasMore: false,
    scrollListHeight: "565px",
  });
  const [selected, setSelected] = useState({
    CertificationIds: [],
    Countries: [],
    IndusteryIds: [],
    KeywordIds: [],
    Languages: [],
    ProfileIds: [],
    SkillIds: [],
    checked: [],
    Type: "Only20",
    limit: 20,
    page: 1,
  });

  const handleCloseModal = () => {
    setShowModal((st) => ({
      ...st,
      show: false,
      data: "",
    }));
  };

  const getSkills = (searchKey) => {
    setLoading((st) => ({ ...st, skill: true }));
    getSkillsApi({ searchKey })
      .then((response) => {
        if (response.success) {
          const skills = response.items.map((item) => ({
            ...item,
            label: item.SkillValue,
            value: item.SkillId,
          }));
          const combinedArray = skills.concat(
            globalState?.defaultList?.SkillIds
          );
          const uniqueObjects = new Set();
          const uniqueArray = combinedArray.filter((obj) => {
            const isDuplicate = uniqueObjects.has(obj.value);
            uniqueObjects.add(obj.value);
            return !isDuplicate;
          });
          setFilter((st) => ({ ...st, SkillIds: uniqueArray }));
          setLoading((st) => ({ ...st, skill: false }));
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const getRole = (searchKey) => {
    setLoading((st) => ({ ...st, role: true }));
    getRolesApi({ searchKey })
      .then((response) => {
        if (response.success) {
          const roles = response.items.map((item) => ({
            ...item,
            label: item.ProfileValue,
            value: item.ProfileId,
          }));

          const combinedArray = roles.concat(
            globalState?.defaultList?.ProfileIds
          );
          const uniqueObjects = new Set();
          const uniqueArray = combinedArray.filter((obj) => {
            const isDuplicate = uniqueObjects.has(obj.value);
            uniqueObjects.add(obj.value);
            return !isDuplicate;
          });
          setFilter((st) => ({ ...st, ProfileIds: uniqueArray }));
        }
        setLoading((st) => ({ ...st, role: false }));
      })
      .catch(() => {
        setLoading((st) => ({ ...st, role: false }));
      });
  };
  const getLocation = (searchKey) => {
    getAllLocationsApi({ searchKey })
      .then((response) => {
        if (response.success) {
          const countries = response.items.map((item) => ({
            ...item,
            label: item.CountryName,
            value: item.CountryId,
          }));
          setFilter((st) => ({ ...st, Countries: countries }));
        }
      })
      .catch(() => { });
  };
  const getLanguages = () => {
    getAllLanguagesApi()
      .then((response) => {
        if (response.success) {
          const languagesList = response.items.map((item) => ({
            ...item,
            label: item.LanguageValue,
            value: item.LanguageId,
          }));

          setFilter((st) => ({ ...st, Languages: languagesList }));
        }
      })
      .catch(() => { });
  };
  const getKeywords = (searchKey) => {
    setLoading((st) => ({
      ...st,
      keyword: true,
    }));
    getKeywordApi({ searchKey })
      .then((response) => {
        if (response.success) {
          const keywords = response.items.map((item) => ({
            ...item,
            label: item.KeywordValue,
            value: item.KeywordId,
          }));
          const combinedArray = keywords.concat(
            globalState?.defaultList?.KeywordIds
          );
          const uniqueObjects = new Set();
          const uniqueArray = combinedArray.filter((obj) => {
            const isDuplicate = uniqueObjects.has(obj.value);
            uniqueObjects.add(obj.value);
            return !isDuplicate;
          });
          setFilter((st) => ({ ...st, KeywordIds: uniqueArray }));
          setLoading((st) => ({ ...st, keyword: false }));
        }
      })
      .catch(() => { });
  };
  const getIndustry = (searchKey) => {
    setLoading((st) => ({ ...st, industry: true }));
    getIndustriesApi({ searchKey }).then((response) => {
      if (response.success) {
        const industries = response.items.map((item) => ({
          ...item,
          label: item.IndustryValue,
          value: item.IndustryId,
        }));
        const combinedArray = industries.concat(
          globalState?.defaultList?.IndusteryIds
        );
        const uniqueObjects = new Set();
        const uniqueArray = combinedArray.filter((obj) => {
          const isDuplicate = uniqueObjects.has(obj.value);
          uniqueObjects.add(obj.value);
          return !isDuplicate;
        });
        setFilter((st) => ({ ...st, IndusteryIds: uniqueArray }));
        setLoading((st) => ({ ...st, industry: false }));
      }
    });
  };
  const getCertification = (searchKey) => {
    setLoading((st) => ({ ...st, certificate: true }));
    getCertificationsApi({ searchKey })
      .then((response) => {
        if (response.success) {
          const certifications = response.items.map((item) => ({
            ...item,
            label: item.CertificationValue,
            value: item.CertificationId,
          }));
          const combinedArray = certifications.concat(
            globalState?.defaultList?.CertificationIds
          );
          const uniqueObjects = new Set();
          const uniqueArray = combinedArray.filter((obj) => {
            const isDuplicate = uniqueObjects.has(obj.value);
            uniqueObjects.add(obj.value);
            return !isDuplicate;
          });
          setFilter((st) => ({ ...st, CertificationIds: uniqueArray }));
        }
        setLoading((st) => ({ ...st, certificate: false }));
      })
      .catch(() => { });
  };
  const getShortlist = () => {
    getShortlistApi()
      .then((data) => {
        setState((st) => ({ ...st, shortListResume: data?.items }));
      })
      .catch(() => { });
  };
  const getSaveSearch = () => {
    setIsLoading(true);
    getSavedSearchesApi()
      .then((data) => {
        if (data.success) {
          if (data.items.length > 0) {
            setUpdateTitleModal((st) => ({
              ...st,
              id: data?.items?.[0]?.SavedSearchId,
              value: data?.items?.[0]?.SearchName,
            }));
            const filterSavedSearch = data?.items?.map((single) => {
              return {
                ...single,
                label: single?.SearchName,
                value: single?.SavedSearchId,
              };
            });
            setState((st) => ({ ...st, saveSearch: filterSavedSearch }));
            if (globalState?.savedSearch == null) {
              handleSaveSearchSelect(data?.items?.[0]?.SavedSearchId, filterSavedSearch);
            }
          } else {
            const info = {
              message: labels.SEARCH_NO_SAVED_SEARCH_VALIDATION,
              status: "info",
            };
            dispatch(notificationAction(info));
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const getOpportunities = () => {
    getOppuntinitiesApi()
      .then((data) => {
        setState((st) => ({ ...st, opportunitiesList: data?.items }));
      })
      .catch((err) => { });
  };

  const createNewShortlist = (id) => {
    const selectedResumesIds =
      selected?.checked?.length == 0 ? [id] : selected?.checked;
    const info = {
      ShortlistName: `New shortlist for ${selectedResumesIds.length
        } resume(s) at ${moment(new Date()).format("D MMMM YYYY")} at ${moment(
          new Date()
        ).format("LTS")}`,
      ResumeIdsList: selectedResumesIds,
    };
    return postShortlistApi(info)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        dispatch(onStateChangeAction({ isLoading: false }));
      });
  };
  const handleExistingShortlistClick = (item, id) => {
    postShortlistApi({
      ShortlistId: item.ShortlistId,
      ShortlistName: item.ShortlistName,
      ResumeIdsList: selected?.checked?.length == 0 ? [id] : selected?.checked,
    })
      .then((data) => {
        if (data.success) {
          const info = {
            message: labels.SEARCH_RESUME_ADDED_SHORTLIST_SUCESSS_MESSAGE,
            status: "success",
          };
          dispatch(notificationAction(info));
          return;
        }
        const info = {
          message: data.message,
          status: "error",
        };
        dispatch(notificationAction(info));
      })
      .catch((err) => { });
  };

  const getFilterData = (name) => {
    const filterObj = { ...globalState?.selected };
    switch (name) {
      case "role":
        return filter?.ProfileIds?.filter((single) => {
          return filterObj?.ProfileIds?.some((row) => single.ProfileId == row);
        });
      case "skill":
        return filter?.SkillIds?.filter((single) => {
          return filterObj?.SkillIds?.some((row) => single.SkillId == row);
        });
      case "language":
        return filter?.Languages?.filter((single) => {
          return filterObj?.Languages?.some((row) => single.LanguageId == row);
        });
      case "keyword":
        return filter?.KeywordIds?.filter((single) => {
          return filterObj?.KeywordIds?.some((row) => single.KeywordId == row);
        });
      case "industry":
        return filter?.IndusteryIds?.filter((single) => {
          return filterObj?.IndusteryIds?.some(
            (row) => single.IndustryId == row
          );
        });
      case "country":
        return filter?.Countries?.filter((single) => {
          return filterObj?.Countries?.some((row) => single.CountryId == row);
        });
      case "certificate":
        return filter?.CertificationIds?.filter((single) => {
          return filterObj?.CertificationIds?.some(
            (row) => single.CertificationId == row
          );
        });
    }
  };

  const handleExistingSaveSearchClick = (id) => {
    setIsLoading(true);
    const saveSearch = {
      SavedSearchId: id,
      SearchName: createSaveSearchModal?.value,
      Certifications: getFilterData("certificate")?.map((item) => {
        return {
          Id: item.CertificationId,
          ExpLevel: 0,
          LookupValue: item.CertificationValue,
        };
      }),
      Countries: getFilterData("country")?.map((item) => {
        return {
          Id: item.CountryId,
          ExpLevel: 0,
          LookupValue: item.CountryName,
        };
      }),
      Industries: getFilterData("industry").map((item) => {
        return {
          Id: item.IndustryId,
          ExpLevel: item.ExperienceLevel,
          LookupValue: item.IndustryValue,
        };
      }),
      Keywords: getFilterData("keyword")?.map((item) => {
        return {
          Id: item.KeywordId,
          ExpLevel: 0,
          LookupValue: item.KeywordValue,
        };
      }),
      Languages: getFilterData("language")?.map((item) => {
        return {
          Id: item.LanguageId,
          ExpLevel: item.ExperienceLevel,
          LookupValue: item.LanguageValue,
        };
      }),
      Profiles: getFilterData("role")?.map((item) => {
        return {
          Id: item?.ProfileId,
          ExpLevel: item.ExperienceLevel,
          LookupValue: item?.ProfileValue,
        };
      }),

      Skills: getFilterData("skill")?.map((item) => {
        return {
          Id: item.SkillId,
          ExpLevel: item.ExperienceLevel,
          LookupValue: item.SkillValue,
        };
      }),
    };
    if (id == 0) {
      postSearchApi(saveSearch)
        .then((data) => {
          if (data.success) {
            setIsLoading(false);
            const info = {
              message: labels.InfoISearchSavedSuccessfully,
              status: "success",
            };
            getSaveSearch();
            setCreateSaveSearchModal((st) => ({
              ...st,
              open: false,
              id: "",
              value: "",
            }));
            dispatch(notificationAction(info));
            return;
          }
          const info = {
            message: data.message,
            status: "error",
          };
          dispatch(notificationAction(info));
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      updateSearchApi(saveSearch)
        .then((data) => {
          if (data.success) {
            const info = {
              message: labels.InfoISearchSavedSuccessfully,
              status: "success",
            };
            setOpen((st) => ({ ...st, saveSearch: !st.saveSearch }));
            dispatch(notificationAction(info));
            getSaveSearch();
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const handleRename = () => {
    updateSavedSearchName(
      createSaveSearchModal?.id,
      createSaveSearchModal?.value
    ).then((res) => {
      getSaveSearch();
      setCreateSaveSearchModal((st) => ({
        ...st,
        open: false,
        id: "",
        value: "",
        title: "",
      }));
    });
  };

  const handleCreateNewShorlistClick = () => {
    setIsLoading(true);
    postShortlistApi({
      ShortlistId: -1,
      ShortlistName: createModal?.value,
      ResumeIdsList:
        selected?.checked?.length == 0 ? [createModal?.id] : selected?.checked,
    })
      .then((data) => {
        if (data.success) {
          getShortlist();
          setCreateModal((st) => ({
            ...st,
            open: !st.open,
            id: "",
            value: "",
          }));
          const info = {
            message: labels.shortlistSuccessAdded,
            status: "success",
          };
          dispatch(notificationAction(info));
          setIsLoading(false);
          return;
        }
        const info = {
          message: data.message,
          status: "error",
        };
        dispatch(notificationAction(info));
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const searchResume = (values) => {
    setSelected((st) => ({ ...st, checked: [] }));
    setIsLoading(true);
    searchResumesApi(values)
      .then((res) => {
        if (res.success) {
          setState((st) => ({
            ...st,
            hasMore: res.items.length < 20 ? false : true,
            resumePage: 1,
          }));
          if (
            values?.IndusteryIds.length == 0 &&
            values?.KeywordIds.length == 0 &&
            values?.ProfileIds.length == 0 &&
            values?.SkillIds.length == 0 &&
            values?.CertificationIds.length == 0 &&
            values?.Languages.length == 0 &&
            values?.Countries.length == 0
          ) {
            dispatch(
              onStateChangeAction({
                resumeList: [],
                selectedResume: false,
              })
            );
            setState((st) => ({ ...st, resumeList: [] }));
          } else {
            dispatch(
              onStateChangeAction({
                resumeList: res?.items,
                selectedResume: false,
              })
            );
            setState((st) => ({ ...st, resumeList: res?.items }));
          }
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setLoading({
      role: true,
      skill: true,
      industry: true,
      certificate: true,
      keyword: true,
    });
    const defaultOption = { ...globalState?.defaultList };
    if (defaultOption?.SkillIds?.length) {
      setFilter((st) => ({ ...st, SkillIds: defaultOption?.SkillIds }));
      setLoading((st) => ({ ...st, skill: false }));
    } else {
      getSkills("a");
    }
    if (defaultOption?.ProfileIds?.length) {
      setFilter((st) => ({ ...st, ProfileIds: defaultOption?.ProfileIds }));
      setLoading((st) => ({ ...st, role: false }));
    } else {
      getRole("a");
    }
    if (defaultOption?.KeywordIds?.length) {
      setFilter((st) => ({ ...st, KeywordIds: defaultOption?.KeywordIds }));
      setLoading((st) => ({ ...st, keyword: false }));
    } else {
      getKeywords("a");
    }
    if (defaultOption?.IndusteryIds?.length) {
      setFilter((st) => ({ ...st, IndusteryIds: defaultOption?.IndusteryIds }));
      setLoading((st) => ({ ...st, industry: false }));
    } else {
      getIndustry("a");
    }
    if (defaultOption?.CertificationIds?.length) {
      setFilter((st) => ({
        ...st,
        CertificationIds: defaultOption?.CertificationIds,
      }));
      setLoading((st) => ({ ...st, certificate: false }));
    } else {
      getCertification("a");
    }
    getLocation("");
    getLanguages();
    getShortlist();
    getOpportunities();
    getSaveSearch();
    window.view = "NEWSAVEDSEARCH";
    window.actionName = "newSaveSearch";
    if (history?.location?.pathname?.split("/")?.length > 2) {
      setUpdateTitleModal((st) => ({
        ...st,
        open: true,
        title: "Save Search",
      }));
    }
  }, []);

  const handleUpdateTitle = () => {
    updateSavedSearchName(updateTitleModal?.id, updateTitleModal?.value)
      .then((res) => {
        getSaveSearch();
        setUpdateTitleModal({ open: false });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const menu = (resume) => {
    return (
      <Menu
        style={{
          height: "300px",
          overflow: "auto",
          width: "290px",
        }}
        className="dropdown-shortlists"
      >
        <Menu.Item
          key="Newest"
          className="first-child-dropdown"
          onClick={() => {
            setCreateModal((st) => ({
              ...st,
              open: !st.open,
              id: resume?.ResumeId,
            }));
          }}
        >
          <span className="span-">Create New Shortlist</span>
          <PlusOutlined />
        </Menu.Item>
        <Menu.Divider />
        {state?.shortListResume?.map((single, index) => (
          <Menu.Item
            key={index}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
            onClick={() => {
              handleExistingShortlistClick(single, resume?.ResumeId);
            }}
          >
            {single?.ShortlistName}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const createNewSaveSearch = (resume) => {
    return (
      <Menu
        style={{
          height: "300px",
          overflow: "auto",
          width: "290px",
        }}
        className="dropdown-shortlists"
      >
        <Menu.Item
          key="Newest"
          className="first-child-dropdown"
          onClick={(e) => {
            setCreateSaveSearchModal((st) => ({
              ...st,
              open: true,
              id: 0,
              title: "Create",
            }));
            setOpen((st) => ({ ...st, saveSearch: !st.saveSearch }));
          }}
        >
          <span className="span-">Create New Search</span>
          <PlusOutlined />
        </Menu.Item>
        <Menu.Divider />
        {state?.saveSearch?.map((single, index) => (
          <Menu.Item
            key={index}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
            onClick={() => {
              handleExistingSaveSearchClick(single?.SavedSearchId);
            }}
          >
            {single?.SearchName}
          </Menu.Item>
        ))}
      </Menu>
    );
  };
  const menuOpportunities = (single) => {
    return (
      <Menu
        style={{
          height: "300px",
          overflow: "auto",
          width: "290px",
        }}
        className="dropdown-shortlists"
      >
        <Menu.Item
          key="Newest"
          className="first-child-dropdown"
          onClick={() => {
            createNewShortlist(single?.ResumeId).then((data) => {
              sessionStorage.setItem("opportunity-new", true);
              history.push(privateRoutes.searcherCreateOpportunity.path);
            });
          }}
        >
          <span className="span-">Create New Opportunity</span>
          <PlusOutlined />
        </Menu.Item>
        <Menu.Divider />
        {state?.opportunitiesList?.map((single, index) => (
          <Menu.Item
            key={index}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
            onClick={() => {
              sessionStorage.setItem("opportunity", JSON.stringify(single));
              history.push(privateRoutes.searcherCreateOpportunity.path);
            }}
          >
            {single?.RequestName}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const handleChange = (value, name, option) => {
    const duplicate = { ...globalState?.selected };
    duplicate[name] = value;
    const duplicateDefaultList = { ...globalState?.defaultList };
    duplicateDefaultList[name] = option;
    dispatch(
      onStateChangeAction({
        selected: duplicate,
        defaultList: duplicateDefaultList,
      })
    );
    searchResume(duplicate);
  };

  const handleSearch = (e, name) => {
    if (e) {
      if (name == "role") {
        getRole(e);
      }
      if (name == "Skill") {
        getSkills(e);
      }
      if (name == "keyword") {
        getKeywords(e);
      }
      if (name == "industry") {
        getIndustry(e);
      }
      if (name == "certificate") {
        getCertification(e);
      }
    }
  };

  const handleClear = () => {
    dispatch(
      onStateChangeAction({
        selected: {
          CertificationIds: [],
          Countries: [],
          IndusteryIds: [],
          KeywordIds: [],
          Languages: [],
          ProfileIds: [],
          SkillIds: [],
          checked: [],
          Type: "Only20",
          limit: 20,
          page: 1,
        },
        resumeList: [],
      })
    );
  };

  const fetchMoreResumes = () => {
    if (!state?.hasMore) {
      return;
    }
    const infos = { ...globalState?.selected };
    onStateChangeAction({ selected: infos });
    infos.page = state?.resumePage + 1;
    setState((st) => ({ ...st, resumePage: st.resumePage + 1 }));
    searchResumesApi(infos).then((data) => {
      if (data.success) {
        setState((st) => ({
          ...st,
          hasMore: data.items.length < 20 ? false : true,
        }));
        const currentResumesList = [...globalState?.resumeList];
        const allSearchedResumesList = currentResumesList?.concat(data?.items);
        dispatch(onStateChangeAction({ resumeList: allSearchedResumesList }));
      }
    });
  };

  // for saved search clicked
  const handleSaveSearchSelect = (selectedSavedSearch, saveSearch) => {
    let filterSaveSearch = null
    if (globalState?.savedSearch == null) {
      filterSaveSearch = saveSearch?.find(x => x?.SavedSearchId == selectedSavedSearch)
    } else {
      filterSaveSearch = state?.saveSearch?.find(x => x?.SavedSearchId == selectedSavedSearch)
    }
    setIsLoading(true);
    getSaveSearchDetailApi(selectedSavedSearch)
      .then((response) => {
        let newSetting = {
          ProfileIds: [],
          SkillIds: [],
          KeywordIds: [],
          CertificationIds: [],
          IndusteryIds: [],
          Countries: [],
          Languages: [],
        };
        const defaultOption = {
          SkillIds: [],
          ProfileIds: [],
          KeywordIds: [],
          IndusteryIds: [],
          CertificationIds: [],
        };
        if (response.success) {
          const savedSearchSettings = response.items.SavedSearchSettings.reduce(
            function (filtered, option) {
              switch (option.LookupTypeId) {
                case 1: {
                  newSetting.ProfileIds.push(option.SearchedPK);
                  defaultOption.ProfileIds.push({
                    value: option?.SearchedPK,
                    label: option?.SearchedValue,
                  });
                  dispatch(
                    onStateChangeAction({
                      collapse: { ...globalState?.collapse, role: true },
                    })
                  );
                  break;
                }
                case 2: {
                  newSetting.SkillIds.push(option.SearchedPK);
                  defaultOption.SkillIds.push({
                    value: option?.SearchedPK,
                    label: option?.SearchedValue,
                  });
                  break;
                }
                case 3: {
                  newSetting.KeywordIds.push(option.SearchedPK);
                  defaultOption.KeywordIds.push({
                    value: option?.SearchedPK,
                    label: option?.SearchedValue,
                  });
                  break;
                }
                case 4: {
                  newSetting.IndusteryIds.push(option.SearchedPK);
                  defaultOption.IndusteryIds.push({
                    value: option?.SearchedPK,
                    label: option?.SearchedValue,
                  });
                  break;
                }
                case 5: {
                  newSetting.CertificationIds.push(option.SearchedPK);
                  defaultOption.CertificationIds.push({
                    value: option?.SearchedPK,
                    label: option?.SearchedValue,
                  });
                  break;
                }
                case 6: {
                  newSetting.Languages.push(option.SearchedPK);
                  break;
                }
                case 7: {
                  newSetting.Countries.push(option.SearchedPK);
                  break;
                }
              }
              filtered = newSetting;
              return filtered;
            },
            []
          );
          setFilter((st) => ({ ...st, ...defaultOption }));
          dispatch(
            onStateChangeAction({
              selected: { ...globalState?.selected, ...savedSearchSettings },
              collapse: {
                ...globalState?.collapse,
                role: true,
                skill: true,
                language: true,
                keyword: true,
                industry: true,
                certificate: true,
              },
              savedSearch: selectedSavedSearch,
              filterSaveSearch: filterSaveSearch,
              defaultList: { ...filter, ...defaultOption },
            })
          );
          searchResume({ ...globalState?.selected, ...savedSearchSettings });
        }
      })
      .catch((res) => {
        setIsLoading(false);
      });
  };

  const handleSaveSearchDelete = () => {
    deleteSavedSearchApi({ id: globalState?.savedSearch })
      .then((response) => {
        if (response.success) {
          setDeleteSearch({ show: false });
          const info = {
            message: labels.SAVED_SEARCH_DELETE_SUCCESS_MESSAGE,
            status: "success",
          };
          dispatch(notificationAction(info));
          dispatch(onStateChangeAction({ savedSearch: null, filterSaveSearch: null, resumeList: [] }));
          getSaveSearch();
        } else {
          setDeleteSearch({ show: false });
          const info = { message: response.message, status: "error" };
          dispatch(notificationAction(info));
        }
      })
      .catch((error) => console.log("Deleted ", error));
  };

  const handleMobileMenuClick = (e) => {
    if (e.key == 0) {
      setIsModalOpen(true);
    }
    if (e.key == 1) {
      const filterId = state?.saveSearch?.find(
        (x) => x?.SavedSearchId == globalState?.savedSearch
      );
      setCreateSaveSearchModal(() => ({
        open: true,
        title: "Edit",
        id: filterId?.SavedSearchId,
        value: filterId?.SearchName,
      }));
    }
    if (e.key == 2) {
      const filterId = state?.saveSearch?.find(
        (x) => x?.SavedSearchId == globalState?.savedSearch
      );
      setDeleteSearch((st) => ({
        ...st,
        show: true,
        title: `Do you want to delete ${filterId?.SearchName}`,
      }));
    }
  };

  return (
    <div className="search-page-new-design h-100">
      <ShortListModal
        open={createModal}
        setOpen={setCreateModal}
        handleCreateNew={handleCreateNewShorlistClick}
      />
      <UpdateTitleModal
        open={updateTitleModal}
        setOpen={setUpdateTitleModal}
        handleUpdateTitle={handleUpdateTitle}
      />
      <SaveSearchModal
        open={createSaveSearchModal}
        setOpen={setCreateSaveSearchModal}
        handleCreateNew={(id) => handleExistingSaveSearchClick(id)}
        handleRename={handleRename}
      />
      <DeleteSaveSearchModal
        open={deleteSerach}
        setOpen={setDeleteSearch}
        handleOk={handleSaveSearchDelete}
      />
      {showModal?.show == true && (
        <DetailModal
          setOpen={handleCloseModal}
          open={showModal}
          selected={globalState?.selected}
          menuOpportunities={menuOpportunities}
          menu={menu}
        />
      )}
      <MobileFilter
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        filter={filter}
        handleChange={handleChange}
        handleSearch={handleSearch}
        globalState={globalState}
        loading={loading}
        setCreateSaveSearchModal={setCreateSaveSearchModal}
        labels={labels}
        state={state}
        handleExistingSaveSearchClick={handleExistingSaveSearchClick}
        handleClear={handleClear}
      />
      <PageWrapper className="">
        {isLoading && <LoadingMask text="" />}
        <Row className="h-100 new-design-search w-100" >
          <Col xs={0} md={7} className="shortlist-lsit-section">
            <div className="h-100 d-flex flex-column">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "12px",
                  borderBottom: "1px solid #F3F3F3"
                }}
              >
                <Select
                  style={{
                    width: "100%",
                    marginRight: "10px",
                    overflow: "auto",
                    border: "1px solid #F3F3F3",
                    borderRadius: "5px"
                  }}
                  placeholder={labels?.SAVED_SEARCH_LIST_PLACEHOLER}
                  size="medium"
                  bordered={false}
                  onChange={(e) => {
                    handleSaveSearchSelect(e);
                  }}
                  showSearch
                  value={globalState?.savedSearch}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.props?.label
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  }
                  options={state?.saveSearch || []}
                ></Select>
                <Dropdown
                  placement="bottomLeft"
                  className="pointer"
                  menu={{
                    items: [
                      {
                        label: "Rename",
                        key: 1,
                        disabled: globalState?.savedSearch == null,
                      },
                      {
                        label: "Delete",
                        key: 2,
                        disabled: globalState?.savedSearch == null,
                      },
                    ],
                    onClick: handleMobileMenuClick,
                  }}
                  trigger={["click"]}
                >
                  <img style={{ height: "32px" }} src={Editicon} alt="" />
                  {/* <MenuDots style={{ fontSize: "18px", cursor: "pointer" }} /> */}
                </Dropdown>
              </div>
              <div style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                padding: "12px",
                borderBottom: "1px solid #F3F3F3",
                overflow: "auto"
              }}>
                <CommonSelect
                  loading={loading?.role}
                  options={filter?.ProfileIds || []}
                  label={"Role"}
                  value={globalState?.selected?.ProfileIds || []}
                  placeholder={"Select role"}
                  handleChange={handleChange}
                  handleSearch={handleSearch}
                  searchParams={"role"}
                  changesParams={"ProfileIds"}
                />
                <CommonSelect
                  options={filter?.Countries}
                  label={"Location"}
                  placeholder={"Select location"}
                  value={globalState?.selected?.Countries || []}
                  handleChange={handleChange}
                  searchParams={"role"}
                  changesParams={"Countries"}
                  search={false}
                />
                <CommonSelect
                  options={filter?.Languages}
                  label={"Language"}
                  value={globalState?.selected?.Languages || []}
                  placeholder={"Select Language"}
                  search={false}
                  handleChange={handleChange}
                  changesParams={"Languages"}
                />

                <CommonSelect
                  options={filter?.SkillIds || []}
                  label={"Skill"}
                  loading={loading?.skill}
                  value={globalState?.selected?.SkillIds || []}
                  placeholder={"Select skills"}
                  handleChange={handleChange}
                  changesParams={"SkillIds"}
                  handleSearch={handleSearch}
                  searchParams={"Skill"}
                  onChange={(e, option) =>
                    handleChange(e, "SkillIds", option)
                  }
                  onSearch={(e) => {
                    handleSearch(e, "Skill");
                  }}
                />

                <CommonSelect
                  options={filter?.KeywordIds || []}
                  label={"Keyword"}
                  loading={loading?.keyword}
                  value={globalState?.selected?.KeywordIds || []}
                  placeholder={"Select keywords"}
                  handleChange={handleChange}
                  changesParams={"KeywordIds"}
                  handleSearch={handleSearch}
                  searchParams={"keyword"}
                />

                <CommonSelect
                  options={filter?.IndusteryIds || []}
                  label={"Industry"}
                  loading={loading?.industry}
                  value={globalState?.selected?.IndusteryIds || []}
                  placeholder={"Select industry"}
                  handleChange={handleChange}
                  changesParams={"IndusteryIds"}
                  handleSearch={handleSearch}
                  searchParams={"industry"}
                />

                <CommonSelect
                  options={filter?.CertificationIds || []}
                  label={"Certification"}
                  loading={loading?.certificate}
                  value={globalState?.selected?.CertificationIds || []}
                  placeholder={"Select certifications"}
                  handleChange={handleChange}
                  changesParams={"CertificationIds"}
                  handleSearch={handleSearch}
                  searchParams={"certificate"}
                />
                <div>
                  <Text className="label m-0">Availability Date</Text>
                  <div className="animated">
                    <DatePicker
                      style={{ width: "100%", marginTop: "6px", border: "1px solid #f3f3f3" }}
                      value={globalState?.selected?.AvailabilityDate}
                      onChange={(e) => handleChange(e, "AvailabilityDate")}
                      bordered={false}
                    />
                  </div>
                </div>

              </div>
              <div className="d-flex justify-content-between gap-16" style={{ padding: "12px", borderTop: "1px solid #F4F2FE" }}>
                <button className="button-last-footer w-100"
                  onClick={() => {
                    handleClear();
                  }}
                >
                  {`Clear Search`}
                </button>
                <Dropdown
                  overlay={createNewSaveSearch()}
                  trigger={["click"]}
                  open={open?.saveSearch}
                  onOpenChange={() => {
                    if (
                      !(
                        globalState?.selected?.IndusteryIds.length ||
                        globalState?.selected?.KeywordIds.length ||
                        globalState?.selected?.ProfileIds.length ||
                        globalState?.selected?.SkillIds.length ||
                        globalState?.selected?.CertificationIds.length ||
                        globalState?.selected?.Languages.length ||
                        globalState?.selected?.Countries.length
                      )
                    ) {
                      const info = {
                        message: labels.SEARCH_NO_CRITERIA_VALIDATION,
                        status: "error",
                      };
                      dispatch(notificationAction(info));
                      return;
                    }
                    setOpen((st) => ({ ...st, saveSearch: !st.saveSearch }));
                  }}
                >
                  <button className="button-footer-opportunity w-100"
                  >
                    {`Save Search`}
                  </button>
                </Dropdown>
              </div>
            </div>
          </Col>
          <Col md={17} xs={24} offset={0.5} className="h-100 main-save-search">
            <div className=" resume-list h-100">
              <Row >
                <Col md={0} xs={24} className="search-for-mobile">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid #F3F3F3",
                      padding: "12px"
                    }}
                  >
                    <Select
                      style={{
                        width: "100%",
                        marginRight: "10px",
                        overflow: "auto",
                        border: "1px solid #F3F3F3",
                        borderRadius: "5px"
                      }}
                      placeholder={labels?.SAVED_SEARCH_LIST_PLACEHOLER}
                      size="medium"
                      bordered={false}
                      onChange={(e) => {
                        handleSaveSearchSelect(e);
                      }}
                      showSearch
                      value={globalState?.savedSearch}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.props?.label
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      }
                      options={state?.saveSearch || []}
                    ></Select>
                    <Dropdown
                      placement="bottomLeft"
                      className="pointer"
                      menu={{
                        items: [
                          {
                            label: "Edit",
                            key: 0,
                          },
                          {
                            label: "Rename",
                            key: 1,
                            disabled: globalState?.savedSearch == null,
                          },
                          {
                            label: "Delete",
                            key: 2,
                            disabled: globalState?.savedSearch == null,
                          },
                        ],
                        onClick: handleMobileMenuClick,
                      }}
                      trigger={["click"]}
                    >
                      <img style={{ height: "32px" }} src={Editicon} alt="" />
                      {/* <MenuDots style={{ fontSize: "18px", cursor: "pointer" }} /> */}
                    </Dropdown>
                  </div>
                </Col>
                <Col xs={24} className="mb-2">
                  <div className="d-flex justify-content-between gap-8 align-items-center"
                    style={{ padding: "16px", borderBottom: "1px solid #f4f2fe" }}
                  >
                    <Title level={5} className="m-0 text-clamp flex-1">{globalState?.filterSaveSearch?.SearchName}
                      {globalState?.filterSaveSearch?.UpdatedOn &&
                        <span style={{ color: "#00000080" }}> {`(Created ${moment(globalState?.filterSaveSearch?.UpdatedOn)?.format("DD-MM-YYYY hh:mm a")})`}
                        </span>}
                    </Title>
                    <div className="title-selected">
                    <Title level={5} className="m-0">{selected?.checked?.length || 0} Selected</Title>
                    </div>
                  </div>
                </Col>
              </Row>
              <ResumeListComponentNewDesign
                resumeList={globalState?.resumeList}
                score={
                  globalState?.selected?.ProfileIds?.length * 100 +
                  globalState?.selected?.SkillIds?.length * 10 +
                  globalState?.selected?.CertificationIds?.length * 20 +
                  globalState?.selected?.IndusteryIds?.length * 10 +
                  globalState?.selected?.KeywordIds?.length * 5
                }
                state={state}
                fetchMoreResumes={fetchMoreResumes}
                selected={selected}
                setSelected={setSelected}
                setShowModal={setShowModal}
                shortListDropdown={menu}
                opportunityDropdown={menuOpportunities}
                name="save-search"
              />
              {globalState?.resumeList?.length > 0 && (

                <Row className="footer-button-">
                  <Row className="w-100" style={{ justifyContent: "flex-end" }}>
                    <Space size={[12, 8]}>
                      <Dropdown
                        overlay={menu()}
                        trigger={["click"]}
                        open={open?.shortList}
                        onOpenChange={() => {
                          if (state?.checked?.length == 0) {
                            const info = {
                              message:
                                labels.InfoIShortlistResumeListSelectionError,
                              status: "error",
                            };
                            dispatch(notificationAction(info));
                            return;
                          }
                          setOpen((st) => ({
                            ...st,
                            shortList: !st.shortList,
                          }));
                        }}
                      >
                        <button className="button-last-footer w-100">
                          {`Bulk Shortlist (${selected?.checked?.length || 0})`}
                        </button>

                      </Dropdown>
                      <Dropdown
                        overlay={menuOpportunities()}
                        trigger={["click"]}
                        open={open?.opportunity}
                        onOpenChange={() => {
                          if (state?.checked?.length == 0) {
                            const info = {
                              message:
                                labels.InfoIShortlistResumeListSelectionError,
                              status: "error",
                            };
                            dispatch(notificationAction(info));
                            return;
                          }
                          setOpen((st) => ({
                            ...st,
                            opportunity: !st.opportunity,
                          }));
                        }}
                      >
                        <button className="button-footer-opportunity w-100">
                          {`Bulk Invite (${selected?.checked?.length || 0})`}
                        </button>
                      </Dropdown>
                    </Space>
                  </Row>
                </Row>
              )}

            </div>
          </Col>
        </Row>
      </PageWrapper>
    </div>
  );
};

NewSavedSearch.defaultProps = {
  isSaveSearch: false,
};

export default withRouter(NewSavedSearch);
